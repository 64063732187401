<template>
  <!--订单详情弹窗组件-->
  <div class="full p_f d_f ali_c j_c">
    <div class="pd_out p_r">
      <div class="title t_a">
        订单详情
      </div>
      <!--预定信息-->
      <div class="order_info">
        <div class="oi_title">预订信息</div>
        <div class="oi_content">
          <div class="item">{{infos.hotelName}}</div>
          <div class="item">
            <span>{{info[0].commoditytName + ' - ' +info[0].bedType}} <span>|</span></span>
            <span v-if="info[0].breakfast > 0">含早 <span>|</span></span>
            <span v-if="info[0].window > 0">有窗 <span>|</span></span>
            <span v-if="info[0].washRoom > 0">卫生间 <span>|</span></span>
            <span>{{info[0].capacity}}人入住 <span v-if="false">|</span></span>
          </div>
          <div class="item d_f ali_c">
            <span class="left">入住时间</span>
            <span class="center black">{{info[0].beginDate.split('-')[1]+'月'+info[0].beginDate.split('-')[2]+'日'}}</span>
            <span class="right black">---</span>
          </div>
          <div class="item d_f ali_c">
            <span class="left">离店时间</span>
            <span class="center black">{{info[0].endDate.split('-')[1]+'月'+info[0].endDate.split('-')[2]+'日'}}</span>
            <span class="right black">{{'共'+info.length+'间'+$getDaysBetween(new Date($renderTime(info[0].beginDate)).getTime(),new Date($renderTime(info[0].endDate)).getTime())+'晚'}}</span>
          </div>
        </div>
      </div>
      <!--入住人信息-->
      <div class="order_info">
        <div class="oi_title">入住人信息</div>
        <div class="oi_content">
          <div class="item d_f ali_c" v-for="(item,index) in info" :key="index">
            <span class="black index">{{index + 1}}</span>
            <span class="black name">{{item.visitorName}}</span>
            <span class="black">{{item.reservationPersonTelephone}}</span>
          </div>
        </div>
      </div>
      <!--金额详情-->
      <div class="order_info">
        <div class="oi_title">金额详情</div>
        <div class="oi_content bb_none">
          <div class="item d_f ali_c j_s" v-for="(item,index) in priceArr" :key="index">
            <span>{{item.stockDate}}</span>
            <span>{{info.length + ' × ￥'+ (item.sellPrice ? item.sellPrice/100 : 0) + ' - 消费券'+ (couponAmount/100)}}</span>
          </div>
          <div class="item d_f ali_c j_s">
            <span>总价</span>
            <span>￥{{totalPrice - couponAmount/100}}</span>
          </div>
        </div>
      </div>
      <div class="close p_a cur_p" @click="closePop">
        <img src="../img/pd_close.png" alt="">
      </div>
    </div>
  </div>
</template>

<script>
  import apis from '../utils/apis'
  export default {
    name: "payDetail",
    props:{
      hotelInfo:{
        type: Object,
        default(){
          return {}
        }
      },
      hodInfo:{
        type: Object,
        default(){
          return {}
        }
      },
      couponAmount:{
        type: Number,
        default(){
          return 0
        }
      },
    },
    data() {
      return {
        infos:{},
        info:[{beginDate:'',endDate:''}],
        totalPrice:0,
        priceArr:[],
      }
    },
    mounted(){
      this.findInfo();
      this.findHotelOrderDetail();
    },
    methods: {
      //订单详情done
      findHotelOrderDetail(){
        let _t = this;
        _t.$get({
          url:apis.findHotelOrderDetail,
          params: _t.hodInfo
        }).then(res => {
          _t.info = JSON.parse(JSON.stringify(res));
          _t.sellerId = res[0].sellerId;
          _t.hotelInfo.sellerId = res[0].sellerId;
          _t.cutDownTime = new Date(_t.$renderTime(res[0].creatorTime)).getTime()+15 * 60 * 1000-new Date().getTime();
          let endTime = new Date(_t.$renderTime(_t.info[0].endDate)).getTime()-24*3600*1000 ;
          _t.priceQuery = {
            roomId:_t.info[0].commodityId,
            startTime:_t.info[0].beginDate,
            endTime:_t.$formatDate(new Date(endTime),'yyyy-MM-dd'),
          };
          _t.queryPriCalendarByRoomIdAndDate();
        });
      },
      //查询费用明细
      queryPriCalendarByRoomIdAndDate(){
        let _t = this;
        _t.$get({
          url:apis.queryPriCalendarByRoomIdAndDate,
          params: _t.priceQuery
        }).then(res => {
          _t.priceArr = res;
          let num = 0;
          _t.priceArr.map(item => {
            let price = item.sellPrice ? item.sellPrice/100 : 0;
            num += price*_t.info.length;
          });
          _t.totalPrice = num;
        });
      },
      //获取酒店详情done
      findInfo(){
        let _t = this;
        _t.$get({
          url:apis.findInfo,
          params: _t.hotelInfo
        }).then(res => {
          _t.infos = res;
        });
      },
      //关闭弹窗
      closePop(){
        this.$emit('close')
      }
    }
  }
</script>

<style scoped lang="scss">
  .close{
    width: 24px;
    height: 24px;
    bottom: -51px;
    left: calc(50% - 12px);
    img{
      width: 100%;
    }
  }
  .pd_out{
    .order_info{
      margin-bottom: 12px;
    }
    .oi_content{
      &.bb_none{
        border-bottom: none;
      }
      .item{
        .name{
          width: 50px;
        }
        .index{
          width: 12px;
          height: 12px;
          line-height: 12px;
          border-radius: 50%;
          text-align: center;
          font-size: 10px;
          border: 1px solid #3E3E3E;
          margin-right: 7px;
          position: relative;
          top: 1px;
        }
        .black{
          color: #3E3E3E!important;
        }
        .center{
          margin-right: 18px;
        }
        .left{
          width: 90px;
        }
        font-size:12px;
        font-weight:500;
        color:rgba(110,110,110,1);
        margin-bottom: 9px;
      }
      border-bottom: 1px solid #D0D0D0;
      padding: 10px 0 0 5px;
    }
    .oi_title{
      font-size:14px;
      font-weight:500;
      color:rgba(62,62,62,1);
    }
    .title{
      font-size:17px;
      font-weight:500;
      color:rgba(62,62,62,1);
      line-height: 57px;
      border-bottom: 1px dashed #D0D0D0;
      margin-bottom: 30px;
    }
    width: 333px;
    padding: 0 14px;
    background: url("../img/pd_bg.png") no-repeat;
    background-size: 100% 100%;
  }
  .full{
    top: 0;
    left: 0;
    background-color: rgba(0,0,0,0.51) !important;
  }
</style>
