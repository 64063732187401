<template>
  <div class="full">
    <div class="order_nav d_f ali_c">
      <img src="../img/black_back.png" alt="" class="cur_p" @click="exit">
      <span>支付订单</span>
    </div>
    <div class="p_content">
      <div class="t_a top">
        <div class="cut_down">支付剩余时间：<van-count-down class="d_i" :time="cutDownTime" /></div>
        <div class="price">￥{{(couponAmount > totalPrice ? 0 : (totalPrice - couponAmount)/100).toFixed(2)}}</div>
        <div class="jfdk" v-show="couponAmount > 0">消费劵抵扣 减{{couponAmount > totalPrice ? totalPrice/100 : couponAmount /100}}</div>
        <div class="cur_p" @click="isPayDetail = true">
          <span>{{info[0].commoditytName}}</span>
          <img src="../img/ff_time_right.png" alt="">
        </div>
      </div>
      <div class="pay_way">
        <div class="item d_f ali_c">
          <div class="p_icon">
            <img src="../img/p_wechat.png" alt="">
          </div>
          <div class="flex_1 ali_c d_f j_s bb_none">
            <span>微信支付</span>
            <!--<img src="../img/p_uncheck.png" alt="">-->
            <img src="../img/p_check.png" alt="">
          </div>
        </div>
        <!--<div class="item d_f ali_c">
          <div class="p_icon">
            <img src="../img/p_alipay.png" alt="">
          </div>
          <div class="flex_1 ali_c d_f j_s bb_none">
            <span>支付宝支付</span>
            &lt;!&ndash;<img src="../img/p_uncheck.png" alt="">&ndash;&gt;
            <img src="../img/p_check.png" alt="">
          </div>
        </div>-->
      </div>
      <!--<div class="hyjf" v-show="totalDiscountPoints > 0 || totalObtainPoint > 0">
        <span><img src="../../../assets/img/icon-jf.png" alt="会员积分" />会员积分</span>
        <span v-show="totalObtainPoint > 0">+{{totalObtainPoint}}</span>
        <span v-show="totalDiscountPoints > 0" class="reduce">-{{totalDiscountPoints}}</span>
      </div>-->
      <div class="btn t_a cur_p" @click="paySure" v-if="cutDownTime>0">确认支付</div>

      <!--订单详情弹窗-->
      <payDetail v-if="isPayDetail" @close="isPayDetail = false" :hotelInfo="hotelInfo" :hodInfo="hodInfo" :couponAmount="couponAmount"></payDetail>
    </div>
  </div>
</template>

<script>
  import { Dialog,Toast, CountDown } from 'vant';
  import payDetail from '../component/payDetail'
  import apis from '../utils/apis'
  import md5 from 'js-md5';
  let loading = null;

  export default {
    name: "hotelPay",
    components:{
      payDetail,
      'van-count-down': CountDown,
    },
    data() {
      return {
        apis:{
          queryOrderList:'/stlsOrder/queryOrderList' // 查询获得积分
        },
        isPayDetail:false,
        cutDownTime:0,
        totalPrice:0,
        orderNumber:this.$route.query.hotelOrderNum,
        info:[{}],
        priceQuery:{},
        sellerId:null,
        hotelInfo:{
          orderNumber:this.$route.query.hotelOrderNum,
          sellerId:null,
        },
        hodInfo:{
          orderNumber:this.$route.query.hotelOrderNum,
          token:this.$global.token,

        },
        totalDiscountPoints: 0, // 使用积分
        totalDiscountAmount: 0, // 积分抵扣总金额
        // money: JSON.parse(this.$route.query.price) // 支付金额
        totalObtainPoint: 0, // 获得积分
        isJump:false,//z支付成功跳转
        couponAmount: 0 // 优惠总额
      }
    },
    mounted(){
      let _t = this;
      _t.findHotelOrderDetail();
      window.appPayDone = (code)=> _t.appPayDone(code);
      _t.$iosBridge.registerhandler('appPayDone',function (code) {
        _t.appPayDone(code)
      });
    },
    beforeRouteLeave(to, from, next) {
      window.clearInterval(this.interVal);
      sessionStorage.clear();
      sessionStorage.setItem('LOGIN_STATUS','2');
      sessionStorage.setItem('token',this.$global.token);
      next();
    },
    created(){
      this.queryOrderList();
    },
    methods: {
      // 查询订单获得积分
      queryOrderList(){
        let me = this;
        me.$get({
          url:me.apis.queryOrderList,
          closeToast:true,
          params: {
            orderNumber: me.orderNumber,
            sortName: 'order_number',
            token:me.$global.token,
          }
        }).then(res => {
          if(res.data.length){
            this.couponAmount = res.data[0].order.discountAmount;
            for(let i in res.data){
              if(res.data[i].order.orderNumber == me.orderNumber){
                me.totalObtainPoint = res.data[i].order.totalObtainPoint
              }
            }
          }
        })
      },
      exit(){
        Dialog.confirm({
          cancelButtonText: '放弃',
          confirmButtonText: '继续支付',
          message: '是否放弃本次支付？',
        })
          .then(() => {
          })
          .catch(() => {
            this.$router.push({
              path:'/hotelInner',
              query:{
                sellerId:this.sellerId,
              }
            })
          });
      },
      appPayDone(code){
        let _t = this;
        if(code == 0){
          _t.interVal = setInterval(function () {
            if(_t.isJump){
              Toast.clear();
              loading.clear();
              window.clearInterval(_t.interVal);
              clearInterval(_t.interVal);
              _t.interVal = null;
              _t.getStatus()
            }else{
              _t.getStatus()
            }
          },1000);
        }else if(code == -1){
          Toast.clear();
          loading.clear();
          Toast('支付失败');
          window.clearInterval(_t.interVal);
          _t.interVal = null;
        }else if(code == -2){
          Toast.clear();
          loading.clear();
          Toast('用户取消');
          window.clearInterval(_t.interVal);
          _t.interVal = null;
        }
      },
      getStatus(){
        let me = this;
        me.$get({
          url:apis.queryOrderList,
          closeToast:true,
          params: {
            orderNumber: me.orderNumber,
            sortName: 'order_number',
            token:me.$global.token,
          }
        }).then(res => {
          if(res.data.length){
            if(res.data[0].order.orderStatus == 4 || res.data[0].order.orderStatus == 5 || res.data[0].order.orderStatus == 7){
              clearInterval(me.interVal);
              window.clearInterval(me.interVal);
              me.interVal = null;
              me.isJump = true;
              if(me.isJump){
                setTimeout(() => {
                  me.$router.push({
                    path:'/hotelPaySuccess',
                    query:{
                      orderNumber:me.orderNumber,
                      phone:res.data[0].order.reservationPersonTelephone,
                      sellerId:me.info[0].sellerId
                    }
                  })
                },1000)
              }
            }
          }
        });
      },
      //订单详情done
      findHotelOrderDetail(){
        let _t = this;
        _t.$get({
          url:apis.findHotelOrderDetail,
          params: _t.hodInfo
        }).then(res => {
          console.log(res)
          _t.info = JSON.parse(JSON.stringify(res));
          _t.sellerId = res[0].sellerId;
          _t.hotelInfo.sellerId = res[0].sellerId;
          _t.cutDownTime = new Date(_t.$renderTime(res[0].creatorTime)).getTime()+15 * 60 * 1000-new Date().getTime();
          let endTime = new Date(_t.$renderTime(_t.info[0].endDate)).getTime()-24*3600*1000 ;
          _t.priceQuery = {
            roomId:_t.info[0].commodityId,
            startTime:_t.info[0].beginDate,
            endTime:_t.$formatDate(new Date(endTime),'yyyy-MM-dd'),
          };
          // _t.findInfo();
          _t.queryPriCalendarByRoomIdAndDate();
          let totalDiscountAmount = 0, totalDiscountPoints = 0;
          _t.info.map( item => {
            totalDiscountAmount += item.totalDiscountAmount
            totalDiscountPoints += item.totalDiscountPoints
          })
          _t.totalDiscountAmount = totalDiscountAmount
          _t.totalDiscountPoints = totalDiscountPoints
        });
      },
      //查询费用明细
      queryPriCalendarByRoomIdAndDate(){
        let _t = this;
        _t.$get({
          url:apis.queryPriCalendarByRoomIdAndDate,
          params: _t.priceQuery
        }).then(res => {
          _t.priceArr = res;
          let num = 0;
          _t.priceArr.map(item => {
            let price = item.sellPrice ? item.sellPrice : 0;
            num += price*_t.info.length;
          });
          _t.totalPrice = num;
        });
      },
      // 确认支付
      paySure(){
        let me = this;
        loading = Toast.loading({
          duration: 0, // 持续展示 toast
          forbidClick: true,
          message: '支付中'
        });
        // let price = me.price ? (me.price*me.buyNum) : 0;

        me.$post2({
          url:apis.pay,
          params: {
            orderNumber: me.orderNumber,
            payType:'app',
            // payType:'mweb',
            // totalFee:(me.totalPrice - me.totalDiscountAmount) > 0 ? (me.totalPrice - me.totalDiscountAmount) : 0,
            totalFee:(me.totalPrice - me.couponAmount) > 0 ? (me.totalPrice - me.couponAmount) : 0,
            token:me.$global.token
          }
        }).then(res => {

          Toast.clear();
          loading.clear();
          let info = me.jsonInfo(res.data.formData);
          let timestamp = me.$global.modelState ? sessionStorage.getItem('timestamp') : Number(sessionStorage.getItem('timestamp'))/1000;
          let signInfo = {
            appid:info.appid,
            timestamp:info.time_stamp,
            partnerid:info.mch_id,
            noncestr:info.nonce_str,
            package: "Sign=WXPay",
            prepayid:info.prepay_id,
          };
          let stringA=me.sortAsc(signInfo);
          let stringSignTemp=stringA+"&key="+this.$global.appPayKey; //注：key为商户平台设置的密钥key
          let sign=md5(stringSignTemp).toUpperCase(); //注：MD5签名方式
          let paySend = {
            appid:info.appid,
            timestamp:info.time_stamp,
            partnerid:info.mch_id,
            noncestr:info.nonce_str,
            packageName: "Sign=WXPay",
            prepayid:info.prepay_id,
            paySign:sign,
            signType:'',
            iOSLink:'',
          };
          me.appPay(JSON.stringify(paySend));
        }).catch(err => {
          Toast(err.message);
          clearInterval(this.interVal);
          me.interVal = null;
        });
      },
      appPay(info){
        let _t = this;
        if (_t.$global.modelState) {
          window.android.payByWeChart(info);
        } else {
          _t.$iosMethod(function(bridge) {
            bridge.callHandler('payByWeChart', info, function(data) {
              // this.$global.token = data?data:null;
            })
          })
        }
      },
      jsonInfo(c){
        var jsonData={}
        c=c.replace("{","").replace("}","");
        c=c.split(',')
        for(var i=0;i<c.length;i++){
          if(c[i].split('=').length === 2){
            jsonData[(c[i].split('=')[0]).replace(/\s+/g,"")]=(c[i].split('=')[1]).replace(/\s+/g,"")
          }else{
            let str = ''
            for(var j = 1;j<c[i].split('=').length;j++){
              str += (c[i].split('=')[j]).replace(/\s+/g,"")+'=';
            }
            jsonData[(c[i].split('=')[0]).replace(/\s+/g,"")]=(str.substring(0,str.length-1))
          }
        }
        return jsonData
      },
      sortAsc(o){
        var n = [];
        for(var k in o) n.push(k);
        n.sort();
        for(var i=0,str='';i<n.length;i++){
          var v = o[n[i]];
          if(v){
            if({}.toString.call(v)=='[object Object]')
              v = sortAsc(v);
            else if({}.toString.call(v)=='[object Array]')
              v = JSON.stringify(v).replace(/:/g,'=');
          }
          str+='&'+n[i]+'='+v;
        }
        return str.slice(1);
      },
    }
  }
</script>

<style scoped lang="scss">
  .btn{
    height:42px;
    background:linear-gradient(75deg,rgba(127,220,79,1),rgba(29,223,80,1));
    border-radius:21px;
    line-height: 42px;
    font-size:15px;
    font-weight:500;
    color:rgba(255,255,255,1);
    margin-top: 20px;
  }
  .pay_way{
    .flex_1{
      span{
        font-size:13px;
        font-weight:bold;
        color:rgba(62,62,62,1);
      }
      img{
        width: 17px;
      }
      padding: 15px 21px 15px 0;
      border-bottom: 1px solid #D0D0D0;
      &.bb_none{
        border-bottom: none;
      }
    }
    .p_icon{
      padding: 14px 10px 15px 21px;
      img{
        width: 17px;
        position: relative;
        top: 2px;
      }
    }
    padding: 9px 0;
    margin-top: 50px;
    background-color: white;
    border-radius:3px;
  }
  .p_content{
    padding: 0 21px;
  }
  .top{
    >.cur_p{
      margin-top: 15px;
    }
    span{
      vertical-align: middle;
    }
    img{
      vertical-align: middle;
      width: 6px;
      margin-left: 8px;
    }
    .price{
      font-size:21px;
      font-weight:bold;
      margin-top: 20px;
    }
    .cut_down,
    .van-count-down{
      font-size:13px;
    }
    font-weight:500;
    color:rgba(62,62,62,1);
    margin-top: 30px;
  }
  .order_nav{
    background: #F0F0F0;
    span{
      color: #3E3E3E!important;
    }
  }
  .full{
    background-color: #F0F0F0;
    .jfdk{
      display: inline-block;
      border: 1px solid #E93411;
      color: #E93411;
      border-radius: 15px;
      padding: 2px 10px;
      font-size: 12px;
      vertical-align: text-top;
      margin-right: 10px;
    }
    .hyjf{
      margin-top: 10px;
      background: #fff;
      border-radius:3px;
      padding: 10px 21px;
      box-sizing: border-box;
      display: flex;
      justify-content: space-between;
      align-items: center;
      span{
        font-size:13px;
        font-weight:bold;
        color:rgba(62,62,62,1);
        img{
          height: 20px;
          margin-right: 5px;
          vertical-align: text-bottom;
        }
      }
      .reduce{
        color: #E93411;
      }
    }
  }
</style>
